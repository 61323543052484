import Vue from "vue";
import Router from "vue-router";
import MNavMain from "@/layout/MNavMain.vue";
// import BackHeader from "@/layout/BackHeader.vue";
import Flooter from "@/layout//Flooter.vue";
import { logSite } from "../etracker/etracker";

const Flindex = () => import("@/pages/Flindex.vue");

const Products = () => import("../pages/products/ProductsMainPage.vue");
const ProductDetailPage = () =>
  import("../pages/products/ProductDetailPage.vue");

// const MiStock = () => import("../pages/demos/MiStock.vue");

const MDE = () => import("../pages/categories/infos/MDE.vue");
const PRT = () => import("../pages/categories/infos/PRT.vue");
const APP = () => import("../pages/categories/infos/APP.vue");
const BSCAN = () => import("../pages/categories/infos/BSCAN.vue");
const TAB = () => import("../pages/categories/infos/TAB.vue");
const RFID = () => import("../pages/categories/infos/RFID.vue");

const Digitalisierung = () => import("../pages/Digitalisierung.vue");
const Developments = () => import("../pages/Developments.vue");

const Unternehmen = () => import("../pages/Flunternehmen.vue");
// const DMC = () => import("../pages/work/DMC.vue");

// const Work = () => import("../pages/Work.vue");

const MDatenschutz = () => import("@/pages/micom/MDatenschutz.vue");
const MImpressum = () => import("../pages/micom/MImpressum.vue");
const MAGB = () => import("@/pages/micom/MAGB.vue");

const PageNotFound = () => import("../pages/PageNotFound.vue");

import store from "@/store/store.js";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "index",
    components: {
      default: Flindex,
      header: MNavMain,
      footer: Flooter,
    },
    meta: {
      title: "Micom - Ihr Digitalisierungsexperte",
    },
    props: {
      header: {
        colorOnScroll: 400,
      },
      footer: {
        // backgroundColor: "black"
      },
    },
  },
  {
    path: "/products",
    alias: ["*/produkte/*", "/produkte"],
    name: "products",
    components: {
      default: Products,
      header: MNavMain,
      footer: Flooter,
    },
    meta: {
      title: "Produkte",
    },
  },
  {
    path: "/category/mde",
    name: "mde",
    title: "MDE",
    components: {
      default: MDE,
      header: MNavMain,
      footer: Flooter,
    },
    params: {},
    meta: {
      title: "MDE",
      smallNavBar: true,
    },
  },
  {
    path: "/category/prt",
    name: "prt",
    smallNavBar: true,
    components: {
      default: PRT,
      header: MNavMain,
      footer: Flooter,
    },
    meta: {
      title: "Drucker",
      smallNavBar: true,
    },
  },
  {
    path: "/category/app",
    name: "app",
    smallNavBar: true,
    components: {
      default: APP,
      header: MNavMain,
      footer: Flooter,
    },
    meta: {
      title: "Apps",
      smallNavBar: true,
    },
  },
  {
    path: "/category/bscan",
    name: "bscan",
    smallNavBar: true,
    components: {
      default: BSCAN,
      footer: Flooter,
    },
    meta: {
      title: "Scanner",
      smallNavBar: true,
    },
  },
  {
    path: "/category/tab",
    name: "tab",
    smallNavBar: true,
    components: {
      default: TAB,
      footer: Flooter,
    },
    meta: {
      title: "Tablets",
      smallNavBar: true,
    },
  },
  {
    path: "/category/rfid",
    name: "rfid",
    smallNavBar: true,
    components: {
      default: RFID,
      footer: Flooter,
    },
    meta: {
      title: "RFID",
      smallNavBar: true,
    },
  },

  // {
  //   path: "/product/:artnr",
  //   name: "productdetail",
  //   smallNavBar: true,
  //   components: {
  //     default: ProductDetail,
  //   },
  //   props: {},
  //   meta: {
  //     smallNavBar: true,
  //   },
  // },
  {
    path: "/product/:series",
    name: "productdetail",
    smallNavBar: true,
    components: {
      default: ProductDetailPage,
    },
    props: {},
    meta: {
      smallNavBar: true,
    },
  },
  {
    path: "/developement",
    alias: ["/lab/*", "/loesungen/*", "/loesungen/"],
    name: "developement",
    components: {
      default: Developments,
      header: MNavMain,
      footer: Flooter,
    },
    props: {},
    meta: {
      title: "Entwicklung",
    },
  },
  {
    path: "/digitaltransform",
    name: "digitaltransform",
    alias: ["/digitalisierung", "/digital"],
    components: {
      default: Digitalisierung,
      header: MNavMain,
      footer: Flooter,
    },
    props: {},
    meta: {
      title: "Digitalisierung",
    },
  },

  {
    path: "/company",
    name: "company",
    components: {
      default: Unternehmen,
      header: MNavMain,
      footer: Flooter,
    },
    props: {},
    meta: {
      title: "Wir",
    },
  },
  // {
  //   path: "/company/team/dmc",
  //   alias: ["/@dmc"],
  //   name: "company",
  //   components: {
  //     default: DMC,
  //     header: MNavMain,
  //     // footer: Flooter
  //   },
  //   props: {},
  //   meta: {
  //     title: "Dennis@Micom",
  //   },
  // },

  // {
  //   path: "/unternehmen/work",
  //   name: "unternehmen-work",
  //   smallNavBar: true,
  //   components: {
  //     default: Work,
  //     header: MNavMain,
  //     footer: Flooter
  //   },
  //   props: {}
  // },
  {
    path: "/impressum",
    name: "impressum",
    smallNavBar: true,
    components: {
      default: MImpressum,
      header: MNavMain,
      footer: Flooter,
    },
    props: {},
    meta: {
      title: "Impressum",
    },
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    smallNavBar: true,
    title: "MICOM",
    components: {
      default: MDatenschutz,
      header: MNavMain,
      footer: Flooter,
    },
    props: {},
    meta: {
      title: "Datenschutz",
    },
  },
  {
    path: "/agb",
    name: "AGB",
    smallNavBar: true,
    components: {
      default: MAGB,
      header: MNavMain,
      footer: Flooter,
    },
    props: {},
    meta: {
      title: "AGBs",
    },
  },

  //  Demos
  // {
  //   path: "/demos/mistock",
  //   name: "MiStock Demo",
  //   components: {
  //     default: MiStock,
  //   },
  //   props: {},
  //   meta: {
  //     title: "MiStock",
  //     hideFrame: true,
  //   },
  // },
  {
    path: "/404",
    name: "PageNotFound",
    meta: {
      title: "404 - Page not found",
    },

    components: {
      default: PageNotFound,
      header: MNavMain,
      footer: Flooter,
    },
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new Router({
  linkExactActiveClass: "active",
  routes: routes,
  mode: "history",

  // mode: "history",

  scrollBehavior: (to) => {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});

router.beforeEach((toRoute, fromRoute, next) => {
  window.document.title =
    toRoute && toRoute.meta.title ? toRoute.meta.title : "Loading...";

  store.state.overrideNavbarSmall = toRoute.meta.smallNavBar;
  store.state.hideFrame = toRoute.meta.hideFrame;

  if (toRoute.meta.title) logSite(window, window.document.title);

  next();
});

export default router;
