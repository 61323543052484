import { render, staticRenderFns } from "./MNavMain.vue?vue&type=template&id=638b7689&scoped=true&"
import script from "./MNavMain.vue?vue&type=script&lang=js&"
export * from "./MNavMain.vue?vue&type=script&lang=js&"
import style0 from "./MNavMain.vue?vue&type=style&index=0&id=638b7689&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "638b7689",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VExpandXTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAppBar,VBtn,VExpandXTransition,VIcon,VSpacer,VTab,VTabs})
