<template>
  <v-app class="app" style="position: relative">
    <m-nav-main v-if="!$store.state.hideNavbar && !$store.state.hideFrame" />

    <v-main class="m-vcontent">
      <router-view />
    </v-main>

    <router-view name="footer" v-if="appLoaded" />

    <v-dialog
      v-if="appLoaded"
      v-model="showUnsavedDialog"
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title> Anfrage </v-card-title>
        <v-card-subtitle>
          Ihre Anfrage wurde noch nicht versendet!
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="showUnsavedDialog = false">
            schließen
          </v-btn>
          <v-btn
            color="primary"
            @click="
              ($store.state.watchers.showAnfrage = true),
                (showUnsavedDialog = false)
            "
          >
            Anfrage öffnen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <contact-chat
      class="chat"
      v-if="appLoaded && showContactChat && !$store.state.hideFrame"
      :shouldExpand="shouldExpand"
    />

    <!-- <v-card
      dark
      color="primary"
      class="data-card"
      v-if="appLoaded && !$store.state.hideFrame"
      :class="showCard ? 'expanded' : 'small'"
    >
      <v-btn
        fab
        @click="showCard = !showCard"
        style="z-index: 10"
        color="primary"
        fixed
        bottom
        left
      >
        <personal-data-secure style="margin: auto" />
      </v-btn>
      <v-card-title> <lock-icon /> Daten ausspionieren? </v-card-title>
      <v-card-subtitle style="padding-left: 4rem; font-size: 1.2em">
        Nicht mit uns!
      </v-card-subtitle>

      <v-card-text style="color: white">
        Für die Verbesserung unserer Dienste und zum Erkennen von Seitenfehlern
        verwenden wir nur
        <span style="margin: 0 4px; letter-spacing: 2px">
          anonymisierte Daten
        </span>
        entsprechend unserer Datenschutzerklärung.<br />
        => Stored in Germany
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="white"
          text
          @click="(showCard = false), $router.push('/datenschutz')"
        >
          Zur Datenschutzerklärung
        </v-btn>
      </v-card-actions>
    </v-card> -->
  </v-app>
</template>

<script>
const ContactChat = () => import("./layout/contact/ContactChat.vue");
import Firebase from "./db/Firebase.js";
import PersonalDataSecure from "./components/icons/PersonalDataSecure.vue";
import LockIcon from "./components/icons/LockIcon.vue";
// import Database from "./db/MDatabase.js";
import MNavMain from "../src/layout/MNavMain.vue";
import { mdiCellphone, mdiEmail, mdiHomeSearch } from "@mdi/js";
import Vue from "vue";

import Products from "../public/datas/Products.json";
import Categories from "../public/datas/Categories.json";

// import { setTrackerLoader } from "./etracker/etracker.js";

export default {
  components: {
    ContactChat,
    PersonalDataSecure,
    LockIcon,
    MNavMain,
  },

  async created() {
    window.onbeforeunload = () => {
      this.showUnsavedDialog = this.isEditing;
      return this.isEditing;
    };
  },

  beforeMount() {
    this.$store.state.backHistory = window.history.length;
  },

  async mounted() {
    Firebase.initialize(this);
    // Database.setCatsListener((cats, featured) => {
    //   this.$store.state.cats = cats;
    //   this.$store.state.catsFeatured = featured;
    // });

    this.appMounted = true;
    this.$store.state.appMounted = true;
    setTimeout(() => {
      this.$store.state.appLoaded = true;
      this.appLoaded = true;
      this.getJSONS();
    }, 500);
    // window.addEventListener("scroll", "handleScroll");
  },

  destroyed() {
    // window.removeEventListener("scroll", "handleScroll");
  },

  data() {
    return {
      showCard: false,
      path: this.$router.currentRoute.path,
      shouldExpand: 0,
      showUnsavedDialog: false,
      scrollY: 0,
      currentPathIndex: 0,
      appMounted: false,
      appLoaded: false,
      wesearchExpanded: false,
      icons: {
        phone: mdiCellphone,
        email: mdiEmail,
        search: mdiHomeSearch,
      },
      pathArray: [
        "",
        "products",
        "digitaltransform",
        "developement",
        "company",
      ],
    };
  },

  watch: {
    $route: "pathChanged",
  },

  methods: {
    // Deprecated
    swipe(direction) {
      switch (direction) {
        case "left":
          if (this.currentPathIndex < 4) {
            this.$router.push("/" + this.pathArray[this.currentPathIndex + 1]);
          } else this.$router.push("/" + this.pathArray[0]);
          break;
        case "right":
          if (this.currentPathIndex > 0) {
            this.$router.push("/" + this.pathArray[this.currentPathIndex - 1]);
          } else this.$router.push("/" + this.pathArray[4]);
          break;
      }
    },

    async pathChanged(route) {
      route;
      // console.log("Route ", route);
      try {
        this.path = this.$router.currentRoute.path;
        this.$store.state.currentPath = this.$router.currentRoute.path;
        this.pathArray.find((path, i) => {
          return this.path.includes(path) && (this.currentPathIndex = i);
        });
      } catch (e) {
        e;
      }
    },

    async handleScroll() {
      this.scrollY = window.scrollY;
    },

    async getJSONS() {
      Vue.set(this.$store.state, "products", Products.entries);
      Vue.set(this.$store.state, "categories", Categories.entries);
      // await this.getJSON("Products", "products");
      // this.getJSON("Categories", "categories");
    },

    async getJSON(filename, storename) {
      const productinfos = await fetch("/datas/" + filename + ".json");
      const infosJSON = await productinfos.json();
      Vue.set(this.$store.state, storename, infosJSON.entries);
    },
  },

  computed: {
    isEditing: {
      get() {
        return this.$store.getters.checkHasChanges ? true : null;
      },
    },
    showContactChat: {
      get() {
        return (
          !this.path.startsWith("/admin") && !this.path.startsWith("/user")
        );
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.app {
  position: relative;
  width: 100vw !important;
  * {
    overflow: hidden !important;
  }
}

html {
  scroll-behavior: smooth !important;
}
.m-vcontent {
  width: 100vw !important;
  // padding-top: 3rem !important;
  min-height: 100vh;
}

* {
  scroll-behavior: smooth !important;
  -webkit-print-color-adjust: exact !important; /*Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}
.data-card {
  z-index: 10;
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  background-color: #77003b;
  overflow: hidden;

  width: 0;
  max-width: 27.5rem;
  height: 0;
  min-height: 0;
  border-radius: 2rem !important;

  transition: all 0.5s ease;

  &.expanded {
    width: 90vw;
    min-height: 17rem;
    @media screen and (max-width: 370px) {
      min-height: 20rem;
    }
    @media screen and (max-width: 350px) {
      min-height: 22rem;
    }
  }
}
</style>

<style lang="scss">
@font-face {
  font-family: "RNSSanz";
  src: local("RNSSanz"), url("./fonts/RNSSanz-Normal.woff2") format("woff2");
}

@font-face {
  font-family: "MySystem";
  src: local("MySystem"), url("./fonts/Alt_SystemcodeV2_Regular.otf");
}

@import "@/assets/scss/flo/flos-styles.scss";
// Fonts
html {
  width: 100vw !important;
  font-size: $font-size-allbase;

  .wesearch {
    transition: all 0.5s cubic-bezier(0.68, -0.6, 0.32, 1.6);
    position: fixed;
    top: 0.25rem;
    left: -1.25rem;
    z-index: 15 !important;
    background-color: white;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    &:hover {
      left: 0;
    }
  }

  @media screen and (min-width: 1600px) {
    font-size: 1.25em;
  }

  .topbg {
    width: 100vw;
    height: 3rem;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;

    background-color: gray;
  }

  /* General Classes */
  * {
    font-family: "RNSSanz", cursive;
  }

  .digital-font {
    // font-family: "RNSSanz";
    * {
      // font-family: "RNSSanz";
    }
  }
  /* Headlines */
  h1 {
    // font-family: "RNSSanz";
  }
  h2 {
    // font-family: "RNSSanz";
  }
  h3 {
    // font-family: "Rockinsoda";
    padding: 8px;
    letter-spacing: 8px;
  }
  h4 {
    // font-family: "Rockinsoda";
  }
  /* Sonst */
  .right-tabs {
    font-family: "RNSSanz" !important;
    * {
      font-family: "RNSSanz" !important;
    }
  }
  .contact-card {
    * {
      // font-family: "RNSSanz";
      text-align: left;
      // transition: all 1s ease !important;
    }
    .contact-note {
      * {
        // font-family: "RNSSanz" !important;
      }
    }
  }
  .floblem-list {
    font-size: 1em;

    * {
      // font-family: "RNSSanz";
      -webkit-line-clamp: unset !important;
      white-space: normal !important;
    }

    .headline {
      // font-family: "RNSSanz" !important;
      font-size: 1.5em !important;
      padding: 1rem 0;
    }
    // .subtext {
    //   padding: 1rem 0;
    //   font-size: 1em !important;
    //   margin: 0 0;
    //   -webkit-line-clamp: unset !important;
    // }
    .anfrage-btn {
      padding: 0 1.25rem;
      * {
        // font-family: "MySystem" !important;
        font-size: 1em !important;
      }
      .icon {
        padding: 0 1rem 0 0;
      }
    }
  }

  .anfrage-btn {
    z-index: 15;
    padding: 0 1rem !important;
    * {
      // font-family: "Rockinsoda" !important;
      font-size: 1rem !important;
    }
    .icon {
      padding: 0;
    }
  }

  .zitat {
    font-family: "MySystem";
    font-size: 20px !important;
    letter-spacing: 0.25rem;
    padding-bottom: 2rem !important;
    margin-right: -25rem !important;
    border-radius: 1rem;
    @media screen and (max-width: 1000px) {
      margin-right: 0 !important;
    }
    .author {
      font-family: "RNSSanz";
      font-size: 0.7em;
      position: absolute;
      right: 12px;
      bottom: 0;
    }
  }
}

.v-card-subtitle {
  margin-top: 5rem !important;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 16px;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.4);
  background-color: transparent;
  background: transparent;
  border-radius: 10px;
  /* margin: 3.5rem 0; */
}

*::-webkit-scrollbar-thumb {
  background-image: -webkit-linear-gradient(
    135deg,
    rgba(201, 142, 96, 1) 0%,
    rgba(189, 98, 70, 1) 18%,
    rgba(199, 55, 55, 1) 48%,
    rgba(151, 16, 16, 1) 100%
  );
  border-radius: 10px;
}

.v-icon {
  /*  width: 40px !important;
  height: 40px !important;
  padding: 0 !important;
  margin: 0 !important; */
}
</style>
