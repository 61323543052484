<template>
  <v-icon class="icon">
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiCodeBracesBox } from "@mdi/js";

export default {
  props: ["isFacebook", "isInsta"],
  data: () => ({
    svgPath: mdiCodeBracesBox,
  }),
};
</script>

<style lang="scss" scoped>
</style>
