import Vue from "vue";
import Vuetify from "vuetify/lib";
// import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

/* 
    current:
    micom-color: #610a0a
*/
const opts = {
  icons: {
    iconfont: "mdiSvg" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      //TODO: BAAAACK
      light: {
        primary: "#77003B", // #d70072
        secondary: "#460015", // #FFCDD2
        accent: "#d70072" // #aa3d65
      },
      dark: {
        primary: "#77003B", // #E53935
        secondary: "#460015", // #FFCDD2
        accent: "#d70072" // #aa3d65
      }
    }
  }
};

export default new Vuetify(opts);
