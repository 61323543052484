// Firebase App (the core Firebase SDK) is always required and must be listed first
// modular
import { initializeApp } from "firebase/app";

import {
  getFirestore,
  onSnapshot,
  doc,
  collection,
  query,
  where,
  addDoc,
  getDoc,
  getDocs,
  startAfter,
  orderBy,
  startAt,
  endAt,
  limit,
} from "firebase/firestore";

import { getStorage } from "firebase/storage";

export const isTest = process.env.NODE_ENV !== "production";
isTest && console.log("Starting Database in Sandbox");

function checkTest(string) {
  return (isTest ? "M" : "") + string;
}
export const featuredCollection = checkTest("Featured");
export const catDoc = checkTest("Cats");
export const recomDoc = checkTest("Recoms");
export const optionDoc = checkTest("Options");

// checkTest;
export const anfrageCollection = checkTest("Anfragen");
export const productCollection = "MProducts";

const firebaseConfig = {
  apiKey: "AIzaSyASiEDTt9PVa2NNHlNL_EMaogUgpYBh9h0",
  authDomain: "micomweb-76167.firebaseapp.com",
  projectId: "micomweb-76167",
  storageBucket: "micomweb-76167.appspot.com",
  messagingSenderId: "1073619418104",
  appId: "1:1073619418104:web:3c0e1164a3619df664740d",
  databaseURL:
    "https://micomweb-76167-default-rtdb.europe-west1.firebasedatabase.app/",
  measurementId: "G-R94R41NTTC",
};

export class Firebase {
  constructor() {
    // this.app = initializeApp(firebaseConfig);
    this.app = initializeApp(firebaseConfig);
    // this.auth = getAuth(this.app);
    this.db = getFirestore(this.app);
    this.storage = getStorage(this.app);
    // this.analytics = getAnalytics(this.app);
    // this.configDB = getDatabase(this.app);

    this.context = null;

    // this.fireUser = new FireUser(this.app, this.auth, this.db);
    // this.fireAnalytics = new FireAnalytics(this.app, this.analytics);

    this.user = null;
    this.userData = null;

    this.onCatSnapshotSet = false;
  }

  /* 
    8i1aReP0HBUbV7EAoxOPZ5389hA2
8i1aReP0HBUbV7EAoxOPZ5389hA2
TB3lJ0lJrpYNZAv6IjF9706giMT2;
gA156xQewyZMIy3ocqfIINTcntS2;
pMtATUOsK7XWy2yCGrggo0xtLE22;

  */

  initialize(context) {
    this.context = context;
    this.setListener();
  }

  setListener() {
    // //console.log("Fire Auth Listener");
    /*  onAuthStateChanged(
      this.auth,
      user => this.setUser(user)
    ); */
  }

  async setUser(/* user */) {
    /*  try {
      if (this.user && this.user.uid == user.uid) return;
    } catch {
      //
    }

    if (!user) {
      this.user = null;
      this.context.$store.state.user = null;
      return;
    }

    const mUser = user
      ? {
          uid: user.uid,
          name: user.displayName,
          email: user.email,
          phone: user.phoneNumber
        }
      : null;
    // //console.log("Check UserData");

    const userData = user ? await this.fireUser.checkUserData(user) : null;
    this.userData = userData;
    // //console.log(userData);
    // //console.log("Getting Role");

    mUser.role = this.fireUser.getRoleFromUserdata(userData);

    // //console.log("Setting user ");
    // //console.log(mUser);

    if (mUser && mUser.role == "admin") {
      this.user = mUser;
      this.context.$store.state.user = mUser;
    } else {
      this.user = null;
      this.context.$store.state.user = null;
    } */
  }

  setDocListener(collRef, docRef, onFound) {
    isTest && console.log("Setting Debug Doc Listener");

    // and sets IDs
    onSnapshot(doc(this.db, collRef, docRef), (result) => {
      onFound(result.data());
    });
  }

  setCollectionToArrayListener(collectionRef, onFound) {
    isTest && console.log("Setting Debug CollToArray Listener");

    onSnapshot(query(collection(this.db, collectionRef)), (result) => {
      const newArray = [];
      try {
        result.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          newArray.push(data);
        });
      } catch (error) {
        // //console.log(error);
      }
      onFound(newArray);
    });
  }

  // Sonderfall
  //  Second Array for splitting
  //  Second onFound for itemToArray push?
  setCatsListener(onFound) {
    isTest && console.log("Setting Debug Cat Listener");

    onSnapshot(doc(this.db, featuredCollection, catDoc), (result) => {
      let newArray = [];
      let newFeaturedArray = [];
      try {
        result.data().entries.forEach((element) => {
          newArray.push(element);
          if (element.compo) newFeaturedArray.push(element);
        });
      } catch (error) {
        // //console.log(error);
      }
      // //console.log("Cats imported");
      isTest &&
        console.log("Imported Categories") &&
        console.log(newFeaturedArray);
      onFound(newArray, newFeaturedArray);
    });
  }

  setConfigListener() {
    //console.log("Auskommentiert " + onFound);
    /*  onValue(ref(this.configDB), snapshot => {
      onFound(snapshot.val());
    }); */
  }

  createMyUserFromFirebaseUser(fireUser) {
    return {
      uid: fireUser.uid,
      name: fireUser.displayName,
      email: fireUser.email,
      phone: fireUser.phoneNumber,
    };
  }

  /* Doc */
  async addDocument(collRef, doc) {
    return await addDoc(collection(this.db, collRef), doc);
  }

  async getDoc(collRef, docRef) {
    return await getDoc(doc(this.db, collRef, docRef));
  }

  async getDocByQuery(query) {
    return await getDocs(query);
  }
  /* End Doc */

  /* Products */
  async getProductsBy(artNr, manu, className, catKey, afterDoc, mLimit) {
    // var query = this.fb.collection(productCollection);
    var queryParams = [];
    //console.log("Query " + queryParams);

    if (artNr) queryParams.push(where("artnr", "==", artNr.trim()));
    if (manu) queryParams.push(where("manu", "==", manu.trim()));
    if (catKey) queryParams.push(where("cat", "==", catKey.trim()));
    if (className) {
      queryParams.push(orderBy("class"));
      queryParams.push(startAt(className));
      queryParams.push(endAt(className + "\uf8ff"));
    }
    if (afterDoc) queryParams.push(startAfter(afterDoc));
    queryParams.push(limit(mLimit ? mLimit : 10));
    //console.log("Query " + queryParams);
    return await getDocs(
      query(collection(this.db, productCollection), ...queryParams)
    );
  }
  /* End Products */

  /* User */
  /* async createUser(email, password, extras) {
    const fireUser = await this.fireUser.createUser(email, password, extras)
      .userCreds.user;
    return this.createMyUserFromFirebaseUser(fireUser);
  }

  async loginUser(email, password) {
    try {
      let result = await signInWithEmailAndPassword(this.auth, email, password);
      // this.logLogin(result.credential.signInMethod);
      return { user: result.user, error: null };
    } catch (error) {
      return { user: null, error: error };
    }
  }

  async signInWithGoogle() {
    try {
      let result = await signInWithPopup(this.auth, new GoogleAuthProvider());
      // this.logLogin(result.credential.signInMethod);
      return { user: result.user, error: null };
    } catch (error) {
      return { user: null, error: error };
    }
  }

  async getUserData(uID) {
    return await this.fireUser.getUserData(uID);
  }

  logmeout() {
    //console.log("Signing out");
    signOut(this.auth);
  }

  getRoleFromUserdata(userdata) {
    return this.fireUser.getRoleFromUserdata(userdata);
  }

  updateUserData(userdata) {
    this.fireUser.updateUserData(userdata, this.user);
  }

  // Deprecated
  async updateUserDataForUser(userdata) {
    return await this.fireUser.updateUserData(userdata, this.user);
  } */
  /* End User */
}

const mfirebase = new Firebase();
export default mfirebase;
