<template>
  <footer class="m-l-footer" :data-background-color="backgroundColor">
    <div class="vertical footer-container">
      <div class="m-l-container horizontal">
        <div class="m-l-col vertical">
          <h5>Company</h5>
          <div class="m-l-separator"></div>
          <!-- v-img :src=img1 max-height="50"></v-img -->
          <a target="blank" href="https://g.page/micom-gmbh">
            MICOM GmbH<br />
            Wuppertal, Germany<br />
            vertrieb@micom.de<br />
            +49 202 277 23 0
          </a>
          <div class="horizontal" style="gap: 0.5rem">
            <a target="blank" href="https://www.facebook.com/micomgmbh">
              <socials-icons class="social-icon" :isFacebook="true" />
            </a>
            <a target="blank" href="https://www.instagram.com/micom_gmbh/">
              <socials-icons class="social-icon" :isInsta="true" />
            </a>
          </div>
        </div>
        <div class="m-l-col vertical">
          <h5>Legal</h5>
          <div class="m-l-separator"></div>
          <span>
            <router-link class="m-l-link" to="/agb">AGBs</router-link><br />
            <router-link class="m-l-link" to="/impressum">
              Impressum</router-link
            >
            <br />
            <router-link class="m-l-link" to="/datenschutz">
              Datenschutzerklärung
            </router-link>
          </span>
        </div>
        <div class="m-l-col vertical">
          <h5>Pages</h5>
          <div class="m-l-separator"></div>
          <span>
            <router-link class="m-l-link" to="/products"> Produkte</router-link>
            <br />
            <router-link class="m-l-link" to="/digitaltransform"
              >Digitalisierung</router-link
            ><br />
            <router-link class="m-l-link" to="/developement">
              Entwicklung
            </router-link>
            <br />
            <router-link class="m-l-link" to="/company">
              Unternehmen
            </router-link>
            <br />
            <!--  <router-link class="m-l-link" to="/company/jobs">
              Jobs & Ausbildungen
            </router-link> -->
          </span>
        </div>

        <div class="m-l-col vertical credits">
          <h5>Credits</h5>
          <div class="m-l-separator"></div>
          <p>
            Website: <br />
            Designed and made by <span style="color: orange">Florus</span>
            <br /><br />
            Illustrations: <br />
            <a href="https://undraw.co/" target="_blank">
              <span style="color: orange"> undraw.co </span>
            </a>
            by
            <a href="https://twitter.com/ninaLimpi" target="_blank">
              <span style="color: orange"> Katerina Limpitsouni </span> </a
            ><br /><br />
            Videos and Images: <br />
            <a href="https://zebra.com" target="_blank">
              <span style="color: orange"> Zebra Technologies </span> </a
            ><br />

            <a href="https://envato.com/" target="_blank">
              <span style="color: orange"> Envato Elements </span>
            </a>
          </p>
        </div>
        <div class="m-l-col vertical tags">
          <h5>Tags</h5>
          <div class="m-l-separator"></div>
          <p class="tags-text">
            <span style="color: orange; font-size: 1.2em">IoT</span>
            Retrofit Automatisierung Predicitve Maintenance<br />
            <span style="color: orange; font-size: 1.2em">AutoID</span> Zebra
            Motorola Honeywell Datalogic <br />
            <span style="color: orange; font-size: 1.2em">Entwicklung</span>
            Software Hardware Elektronik <br />
            <span style="color: orange; font-size: 1.2em">Digitalisierung</span>
            eCommerce Warenfluss Außendienst<br />
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import SocialsIcons from "../components/icons/SocialsIcons.vue";
export default {
  name: "MFooter",
  components: {
    SocialsIcons,
    //nix: besser: Footer Desktop / Handy
  },
  props: {
    backgroundColor: String,
    type: String,
    img1: {
      type: String,
      default: require("@/assets/img/logo/Logo_MICOM_weiß.webp"),
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/flo/flos-styles.scss";

.footer-container {
  /*  align-items: center; */

  .featured {
    font-size: 0.8em;
  }
}

.m-l-footer {
  background-color: $micom-darkblue; // $black-color;      // sollte eigentlich mit prop gehen...
  * {
    color: white;
  }
  a {
    transition: all 0.25s ease;
    color: white;
    text-decoration: none !important;

    &:hover {
      color: orange;
    }
  }
  .social-icon {
    color: white;
    &:hover {
      color: orange;
    }
  }
  .m-l-container {
    width: 100%;
    flex-wrap: wrap;
    padding: 0 2rem 3rem 2rem;
    align-content: center;
    color: white;

    > * {
      margin: 0 2vw;
    }

    .m-l-col {
      min-width: 10rem;
      max-width: 33vw;
      min-height: 20vh;

      > * {
        margin: 0.25rem 0.25rem;
      }

      padding: 2rem 0.25rem;
      &.tags {
        min-width: 20rem;
      }
      &.credits {
        p {
          font-size: 0.8em;
        }
      }
      .tags-text {
        font-size: 0.8rem;
      }
    }
  }
  .m-l-separator {
    background-color: $primary-color;
    margin: 0 0 8px 0;
    padding: 1px;
  }
}
</style>
