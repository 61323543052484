<template>
  <v-icon class="icon">
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiTabletCellphone } from "@mdi/js";

export default {
  data() {
    return {
      svgPath: mdiTabletCellphone,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
