import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuex from "vuex";
import store from "./store/store.js";
import axios from "axios";
import VueAxios from "vue-axios";
import vueDebounce from "vue-debounce";
import VueMeta from "vue-meta";

import scrollAnim from "./directives/scroll-animations";
Vue.directive("scrollanimation", scrollAnim);

import vuetify from "@/plugins/vuetify";
// import "vuetify/dist/vuetify.min.css";
import Vuelidate from "vuelidate";

import "@/assets/scss/micom/mvariables.scss";
import "@/assets/scss/micom/pages.scss";

import VueVideoPlayer from "vue-video-player";

// require videojs style
import "video.js/dist/video-js.css";

Vue.use(VueVideoPlayer);

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
// Vue.use(VueMaterial);
Vue.use(vueDebounce);
Vue.use(VueMeta);

new Vue({
  router,
  Vuex,
  store,
  vuetify,
  axios,
  Vuelidate,
  // VueMaterial,
  render: h => h(App)
}).$mount("#app");
