<!-- Navbar für alle Landing-Pages. Header der LP wrid dann um xx rem im mounted() (der LP) hochgezogen, um unter der AppBar zu liegen -->
<!-- OM -->
<template>
  <div class="moutter">
    <v-app-bar
      app
      id="MNavMain"
      class="m-nav-main"
      :class="expanded ? 'expanded' : 'small'"
      color="#fff"
      height="$micom-app-bar-height"
      elevate-on-scroll
    >
      <v-tabs
        class="tab-cont"
        height="2.5rem"
        optional
        round
        grow
        centered
        hide-slider
        style="margin: auto"
        slider-color="transparent"
        show-arrows
      >
        <template slot="prev-icon">
          <v-btn
            rounded
            dark
            color="white"
            height="100%"
            elevation="0"
            :to="{ name: 'index' }"
            style="padding: 12px 8px; margin: auto 1rem"
          >
            <micom-icon :small="!expanded" />
          </v-btn>
        </template>
        <v-btn
          rounded
          dark
          color="white"
          height="100%"
          elevation="0"
          :to="{ name: 'index' }"
          style="padding: 12px 8px; margin: auto 1rem"
        >
          <micom-icon :small="!expanded" />
        </v-btn>
        <v-tab class="tab" style="padding: 0.5rem" :to="{ name: 'products' }">
          <tablet-and-phone-icon />
          <v-expand-x-transition>
            <span
              style="margin-left: 0.5rem"
              class="hidden-sm-and-down"
              v-if="expanded"
            >
              Produkte
            </span>
          </v-expand-x-transition>
        </v-tab>
        <v-spacer v-if="expanded" />
        <v-tab
          class="tab"
          style="padding: 0.5rem"
          :to="{ name: 'digitaltransform' }"
        >
          <dashboard-icon />
          <v-expand-x-transition>
            <span
              style="margin-left: 0.5rem"
              class="hidden-sm-and-down"
              v-if="expanded"
            >
              Digitalisierung
            </span>
          </v-expand-x-transition>
        </v-tab>
        <v-tab style="padding: 0.5rem" :to="{ name: 'developement' }">
          <code-icon />
          <v-expand-x-transition>
            <span
              style="margin-left: 0.5rem"
              class="hidden-sm-and-down"
              v-if="expanded"
            >
              Entwicklung
            </span>
          </v-expand-x-transition>
        </v-tab>
        <v-spacer v-if="expanded" />
        <v-tab
          style="padding: 0.5rem"
          :style="{ 'margin-right': expanded ? '' : '' }"
          :to="{ name: 'company' }"
        >
          <domain-icon />
          <v-expand-x-transition>
            <span
              style="margin-left: 0.5rem"
              class="hidden-sm-and-down"
              v-if="expanded"
            >
              Wir
            </span>
          </v-expand-x-transition>
        </v-tab>
        <v-spacer v-if="expanded" />
        <div
          class="horizontal gapped centered hidden-md-and-up"
          :style="{ top: expanded ? '-4px' : '8px' }"
        >
          <v-btn
            fab
            small
            icon
            elevation="0"
            color="primary"
            href="tel:+49202277230"
          >
            <v-icon>{{ icons.phone }}</v-icon>
          </v-btn>
          <v-btn
            fab
            small
            icon
            elevation="0"
            color="primary"
            href="mailto:info@micom.de"
          >
            <v-icon>{{ icons.email }}</v-icon>
          </v-btn>
        </div>
      </v-tabs>
    </v-app-bar>
    <div
      class="vertical centered quick-btns hidden-sm-and-down"
      :class="expanded ? '' : 'mcolored'"
    >
      <div class="horizontal gapped">
        <v-btn
          rounded
          class="quick-fab top left"
          color="white"
          dark
          elevation="4"
          href="tel:+49202277230"
        >
          <v-icon class="icon" color="primary">{{ icons.phone }}</v-icon>
          <span class="text"> 0202 27723 0 </span>
        </v-btn>
        <v-btn
          rounded
          :small="expanded"
          class="quick-fab top right"
          color="white"
          dark
          elevation-10
          href="mailto:info@micom.de"
        >
          <v-icon class="icon" color="primary">{{ icons.email }}</v-icon>
          <span class="text"> info@micom.de </span>
        </v-btn>
      </div>
      <div class="horizontal gapped">
        <v-btn
          class="quick-fab bottom left"
          color="white"
          rounded
          elevation="4"
          @click="copyToClip('+49202277230')"
        >
          <v-icon class="icon" color="primary">{{ icons.copy }}</v-icon>
        </v-btn>

        <v-btn
          class="quick-fab bottom right"
          color="white"
          rounded
          elevation="4"
          @click="copyToClip('info@micom.de')"
        >
          <v-icon class="icon" color="primary">{{ icons.copy }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import MDatabase from "@/db/Firebase.js";
import TabletAndPhoneIcon from "../components/icons/TabletAndPhoneIcon.vue";
import DashboardIcon from "../components/icons/DashboardIcon.vue";
import CodeIcon from "../components/icons/CodeIcon.vue";
import DomainIcon from "../components/icons/DomainIcon.vue";
import MicomIcon from "../components/icons/MicomIcom.vue";
import { mdiContentCopy, mdiEmail, mdiPhone, mdiStoreSearch } from "@mdi/js";

export default {
  name: "MNavMain",

  components: {
    TabletAndPhoneIcon,
    DashboardIcon,
    CodeIcon,
    DomainIcon,
    MicomIcon,
  },

  data: () => ({
    db: MDatabase,
    drawer: null,
    showTabTitles: true,
    windowWidth: window.innerWidth,

    shouldExpand: true,
    expanded: true,

    emailHovered: false,
    phoneHovered: false,

    scrollListenerSet: false,

    timeout: null,
    items: [
      "Home",
      "Produkte",
      "Digitalisierung",
      "Entwicklung",
      "Unternehmen",
    ],
    targets: [
      "index",
      "products",
      "digitaltransform",
      "developement",
      "company",
    ],
    icons: {
      phone: mdiPhone,
      email: mdiEmail,
      copy: mdiContentCopy,
      search: mdiStoreSearch,
    },
  }),

  computed: {
    isAdmin() {
      if (!this.$store.state.user) return false;
      return this.$store.state.user.role == "admin";
    },

    loginLink: {
      get() {
        return this.$store.state.user == null
          ? { name: "login" }
          : { path: "/user/" + this.$store.state.user.uid };
      },
    },

    username: {
      get() {
        if (this.$store.state.user == null) return "login";
        let name = "User";
        try {
          if (this.$store.state.user.name) name = this.$store.state.user.name;
          else if (this.db.userData.username) name = this.db.userData.username;
          else name = this.$store.state.user.email.split("@")[0];
          return name;
        } catch (e) {
          if (!name) name = "N/A";
          return name;
        }
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      this.setScrollListener();
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("scroll", this.onScroll);
  },

  watch: {
    "$store.state.overrideNavbarSmall": function (value) {
      this.shouldExpand = !value;
      if (window.document.getElementById("MNavMain")) this.setScroll();
    },

    expanded: function (value) {
      this.$store.state.navBarExpanded = value;
    },
  },

  methods: {
    onScroll() {
      if (this.$store.state.overrideNavbarSmall) {
        return;
      }
      let isScrolled = window.scrollY > window.innerWidth * 0.2;
      if (
        !this.$store.state.preventScrollListeners &&
        this.shouldExpand == isScrolled
      ) {
        this.shouldExpand = !isScrolled;

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (window.document.getElementById("MNavMain")) this.setScroll();
        }, 200);
      }
    },
    onResize() {
      this.showTabTitles = window.innerWidth > 800;
      this.windowWidth = window.innerWidth;
      this.setScrollListener();
    },
    setScrollListener() {
      if (!this.scrollListenerSet) {
        if (window.innerWidth < 800) return;
        window.addEventListener("scroll", this.onScroll);
        this.scrollListenerSet = true;
        return;
      }
      if (this.scrollListenerSet) {
        window.removeEventListener("scroll", this.onScroll);
        this.scrollListenerSet = false;
      }
    },
    setScroll() {
      this.textVisible = this.shouldExpand;
      this.expanded = this.shouldExpand;

      // let setFirst, setLast;
      // if (!this.shouldExpand) {
      //   setFirst = () => (this.textVisible = false);
      //   setLast = () => (this.expanded = false);
      // } else {
      //   setLast = () => (this.textVisible = true);
      //   setFirst = () => (this.expanded = true);
      // }
      // setFirst();
      // setTimeout(
      //   () => {
      //     setLast();
      //   },
      //   this.shouldExpand ? 1200 : 200
      // );
    },

    copyToClip(value) {
      navigator.clipboard.writeText(value);
      alert(value + " kopiert.");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/flo/flos-styles.scss";
* {
  z-index: 10 !important;
  text-decoration: none !important;
}

.moutter {
  position: relative;
  width: 100vw;
  max-width: 100vw;

  * {
    transition: all 1s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  .quick-btns {
    z-index: 10;
    position: fixed;
    right: 8px;
    top: 16px;
    border-radius: 5rem;

    .text {
      width: 4px;
      opacity: 0;
    }

    .quick-fab {
      min-width: 48px;
      min-height: 48px;
      max-width: 48px;
      max-height: 48px;
      z-index: 10 !important;
      border: 4px solid #77003b !important;
      .icon {
        padding-left: 4px;
      }

      &.bottom {
        opacity: 0;
      }
    }

    &:hover {
      * {
        transition: all 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
      }

      .text {
        width: 140px;
        opacity: 1;
      }

      .quick-fab {
        background-color: #77003b !important;
        color: white !important;
        min-height: 80px;
        min-width: 240px;
        max-width: 66vw;
        margin: 0 !important;

        .icon {
          color: #fff !important;
        }

        &:hover {
          transform: scale(1.05);
          z-index: 11 !important;
        }

        &.top {
          &.left {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
            &:hover {
              transform: translate(-4px, -4px);
            }
          }
          &.right {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            &:hover {
              transform: translate(4px, -4px);
            }
          }
        }

        &.bottom {
          opacity: 1;
          min-height: 40px;
          max-height: 40px;
          min-width: 240px !important;
          max-width: 66vw !important;

          &.left {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            &:hover {
              // transform: translate(-4px, -0px);
            }
          }
          &.right {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:hover {
              // transform: translate(4px, -0px);
            }
          }
        }
      }
    }
  }
}

.nav-outter {
  overflow-x: hidden;
}
#zzzMNavMain {
  .v-tabs-slider {
    max-width: 24px;
    margin: 0 0;
    color: red;
  }
  v-tab &::before {
    display: none;
  }
}
.tab-text {
  font-size: 0.8rem;
}
.m-nav-main {
  // height:  $micom-app-bar-height;  // klappt nicht - macht mounted() in Index & Co.

  width: 100vw;
  max-width: 100vw;
  height: 3rem;
  padding: 0 !important;
  font-size: 1rem;
  color: $micom;
  margin: 0 auto !important;
  transition: all 0.5s cubic-bezier(0.22, 1, 0.36, 1);

  background-color: transparent;

  &.expanded {
    width: 100vw;
    margin: 0;
    border: 0px solid #77003b !important;
  }
  &.small {
    border-radius: 2rem !important;
    width: calc(640px);
    margin-top: 1vh !important;
    max-width: 97vw;
    border: 2px solid #77003b !important;
  }
}
.m-nav-menuitems {
  font-size: $font-size-small;
  font-weight: $font-weight-bold;
  color: $micom !important;
}
.m-nav-logo-container {
  width: 150px;
  overflow: hidden;
  margin: 0 auto;
}
</style>
