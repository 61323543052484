<template>
  <div class="horizontal centered">
    <v-img>
      <svg
        width="29"
        height="100%"
        viewBox="0 0 29 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 16C0 16 11.8605 1.35631 12.816 0.599965C14.5188 -0.748602 16.7061 0.787783 16.6825 2.23111C16.6556 3.86168 16.6556 6.46068 16.6544 10.2182C16.6544 10.5961 17.0242 10.4934 17.258 10.1928C17.5407 9.82956 20.4643 6.20856 25.4959 0.653547C26.7913 -0.776803 29 0.352925 29 2.00211C29 7.92148 28.9792 8.46528 28.9792 16H25.7834C25.7834 11.2498 25.7117 8.84252 25.7303 5.65018C25.7319 5.44883 25.4892 5.49113 25.4122 5.57686C22.7297 8.54924 18.4613 13.6683 17.1293 15.137C15.4557 16.983 13.1464 15.5741 13.1464 13.6609C13.1464 10.6852 13.134 10.1477 13.134 6.09237C13.134 5.61409 12.8294 5.80529 12.5681 6.10647C8.68915 10.5673 4.00227 16 4.00227 16H0Z"
          fill="#77003B"
        />
      </svg>
    </v-img>
    <v-expand-x-transition>
      <v-img v-if="!small" class="hidden-sm-and-down">
        <svg
          width="64"
          height="100%"
          viewBox="0 0 64 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M64 15.933C64 15.933 52.1402 1.35649 51.1849 0.599579C49.4815 -0.748989 47.2953 0.787961 47.3178 2.23072C47.3453 3.86186 47.3453 6.46029 47.3453 10.2178C47.347 10.5957 46.9772 10.493 46.7434 10.1924C46.4596 9.82917 43.5372 6.20818 38.5052 0.653161C37.2103 -0.776626 35 0.353103 35 2.00229C35 7.9211 35.0214 8.39826 35.0214 15.933H38.2854C38.2854 11.1828 38.2888 8.84327 38.2702 5.65036C38.2691 5.44901 38.5119 5.49074 38.5883 5.57648C41.2713 8.54885 45.5391 13.6679 46.8716 15.1377C48.5441 16.9826 50.855 15.5737 50.855 13.6617C50.855 10.6848 50.8668 10.1473 50.8668 6.09199C50.8668 5.6137 51.1714 5.8049 51.4321 6.10609C55.311 10.5669 59.9306 15.933 59.9306 15.933H64Z"
            fill="#77003B"
          />
          <path
            d="M17 8C17 3.58175 20.5818 0 25.0005 0C29.4182 0 33 3.58175 33 8C33 12.4182 29.4182 16 25.0005 16C20.5818 16 17 12.4182 17 8ZM20 8C20 10.7612 22.2388 13 25.0006 13C27.7612 13 30 10.7612 30 8C30 5.23879 27.7612 3 25.0006 3C22.2388 3 20 5.23879 20 8Z"
            fill="#77003B"
          />
          <path
            d="M15.7045 11.0393C16.1077 11.4479 18 13.2932 18 13.2932C16.6463 14.7737 14.5847 16 12.3367 16C8.6958 16 4 13.6454 4 7.83678C4 4.09421 7.43281 0 11.7511 0C13.3435 0 16.4076 0.731206 18 2.98069C17.427 3.58538 15.8668 5.06751 15.8668 5.06751C14.4126 3.90196 13.8772 3.33233 11.8429 3.33233C9.59825 3.33233 7.09521 4.90374 7.09521 8.07175C7.09521 10.0337 8.6958 12.8451 12.1286 12.8451C14.0728 12.8451 15.314 11.461 15.7045 11.0393Z"
            fill="#77003B"
          />
          <path
            d="M3 0L2.99583 16H0C0 12.653 0.0213509 0 0.0213509 0H3Z"
            fill="#77003B"
          />
        </svg>
      </v-img>
    </v-expand-x-transition>
  </div>
</template>
<script>
export default {
  props: ["color", "small"],
  //
};
</script>
<style lang="scss">
svg {
  max-width: 100% !important;
}
.hide {
  animation: hide 2s ease;
}

@keyframes hide {
  to {
    width: 0;
    fill: transparent;
    transform: translateX(-10%);
    // opacity: 0;
  }
}
</style>
