<template>
  <v-icon class="icon">
    {{ svgPath }}
  </v-icon>
</template>

<script>
import { mdiShieldAccount } from "@mdi/js";

export default {
  data: () => ({
    svgPath: mdiShieldAccount,
  }),
};
</script>

<style lang="scss" scoped>
</style>
