<template>
  <v-icon class="icon">
    {{ isFacebook ? svgFace : isInsta ? svgInsta : "" }}
  </v-icon>
</template>

<script>
import { mdiFacebook, mdiInstagram } from "@mdi/js";

export default {
  props: ["isFacebook", "isInsta"],
  data: () => ({
    svgFace: mdiFacebook,
    svgInsta: mdiInstagram,
  }),
};
</script>

<style lang="scss" scoped>
</style>
