let lastSiteLog = null;
let lastAreaLog = null;

export function logSite(window, pageName) {
  if (typeof window._etracker === "object" && pageName != lastSiteLog) {
    window.et_eC_Wrapper({ et_et: "irKZBV", et_pagename: pageName });
    lastSiteLog = pageName;
  }
}
export function logArea(window, areaName) {
  if (typeof window._etracker === "object" && areaName != lastAreaLog) {
    window.et_eC_Wrapper({ et_et: "irKZBV", et_areas: areaName });
    lastAreaLog = areaName;
  }
}
