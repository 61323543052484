import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    count: 0,
    recoms: [],
    cats: [],
    catsFeatured: [],
    productHash: null,
    user: null,
    backHistory: [],
    lastPositions: {},
    overrideNavbarSmall: false,
    preventScrollListeners: false,
    navBarExpanded: null,
    hideNavbar: false,

    products: [],
    categories: [],

    // showBanner: false,

    anfrage: {
      user: {
        // Kontaktdaten
        name: "",
        email: "",
        dsread: false,
        // pricemodel: "Starter"
      },

      prodcart: [], // Warenkorb
      chkdItems: [], // angeklickte Fragen
      chkdAnregungItems: [], // angeklickte Fragen
      chkdThingsForMessage: [], // angeklcikte Bubbles (DevPage)
      message: "",
      finished: false,
      timestamp: "",
      timestampUpdated: "",
    },

    watchers: {
      showAnfrage: false,
    },
  },

  getters: {
    hasAccount: (state) => {
      return (
        state.anfrage.user.name.length > 0 &&
        state.anfrage.user.email.length > 0 &&
        state.anfrage.user.dsread
      );
    },
    checkHasChanges: (state) => {
      return (
        state.anfrage.user.name.length > 0 ||
        state.anfrage.user.email.length > 0 ||
        state.anfrage.user.dsread ||
        state.anfrage.prodcart.length > 0 ||
        state.anfrage.chkdItems.length > 0 ||
        state.anfrage.chkdAnregungItems.length > 0 ||
        state.anfrage.chkdThingsForMessage.length > 0 ||
        state.anfrage.message.length > 0
      );
    },
  },

  mutations: {
    resetAnfrage() {
      this.state.anfrage = {
        user: {
          // Kontaktdaten
          name: "",
          email: "",
          dsread: false,
          // pricemodel: "Starter"
        },

        prodcart: [], // Warenkorb
        chkdItems: [], // angeklickte Fragen
        chkdAnregungItems: [], // angeklickte Fragen
        chkdThingsForMessage: [], // angeklickte Bubbles (DevPage)
        message: "",
        finished: false,
        timestamp: "",
        timestampUpdated: "",
      };
    },

    addToCart(state, payload) {
      var found = state.anfrage.prodcart.findIndex(function (x) {
        return x.ID == payload.ID;
      });
      ////console.log("store: " + found);
      if (found == -1) {
        payload.qty = 1;
        state.anfrage.prodcart.push(payload);
      } else {
        // das hinzuzählen will einfach nicht: also lassen wir es auf '1' !!!!!!!!!!
        // if(state.anfrage.prodcart[found].qty == undefined) {//console.log("store0: undef"); state.anfrage.prodcart[found].qty = 1;}
        // //var qty = state.anfrage.prodcart[found].qty;
        // // state.anfrage.prodcart[found] = payload;
        // state.anfrage.prodcart[found].qty +=  1;
        // //console.log("store1: " + payload.qty+"/"+state.anfrage.prodcart[found].qty+"/"+state.anfrage.prodcart[found].ID);
      }
    },

    removeFromCart(state, payload) {
      // Produkt aus Warenkorb entnehmen
      var nfound = state.anfrage.prodcart.findIndex(function (x) {
        return x.ID === payload.ID;
      });
      // //console.log("Store: "+ nfound);
      state.anfrage.prodcart.splice(nfound, 1); // löschen
    },

    /* Checked Items */
    addToChkdItems(state, payload) {
      /*  */
      var found = state.anfrage.chkdItems.findIndex(function (x) {
        return x.ID == payload.ID;
      });
      ////console.log("store: " + found);
      if (found == -1) {
        state.anfrage.chkdItems.push(payload);
      }
    },

    removeFromChkdItems(state, payload) {
      var nfound = state.anfrage.chkdItems.findIndex(function (x) {
        return x.ID === payload.ID;
      });
      // //console.log("Store: "+ nfound);
      state.anfrage.chkdItems.splice(nfound, 1); // löschen
    },
  },
  actions: {},
  modules: {},
});
